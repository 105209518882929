import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as $ from 'jquery';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.page.html',
  styleUrls: ['./examples.page.scss'],
})
export class ExamplesPage implements OnInit {
	examples: any;
	items: any = [];
	list_items: any = [];
	searchTerm: any;
	timer: number = 3;

  	constructor(private modalController: ModalController, navParams: NavParams) { }

	ngOnInit() {
	    this.examples = (window as any).example_heads;
	    var self = this;
	    $.each(this.examples, function(index, example) {
	    	self.items.push(index);
	    });
	}

	public timerUp() {
		this.timer++;
		console.log(this.timer);
	}

   setFilteredItems() {
    	var searchterm = this.searchTerm;
    	console.log(this.items);

		this.list_items = this.items.filter(function(el) {
			return el.toLowerCase().indexOf(searchterm.toLowerCase()) >= 0;
		}); 
		
		this.timer = 0;   		
    }

    leaveModal() {
    	this.myDismiss(0);
    }

    async myDismiss(value) {
      await this.modalController.dismiss(value);
    }

    chooseItem(value) {
    	this.myDismiss(value);
    }
}
